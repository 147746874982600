import { NewAccountInfos } from '../components/dashboard-create-user/DashboardCreateUser';
import { getSessionValue } from './storage';
import { customFetch, parseSteps } from './utils';
import { getClient } from 'src/reducers/contentful.reducer';

export const getBaseUrl = () => process.env.REACT_APP_HEROKU_TARGET;
//*********************************************************************************
//****************      AUTH        **********************************************
//*********************************************************************************
export const createAccount = async (accountInfos: NewAccountInfos) => {
  return await customFetch(`${getBaseUrl()}/auth/createAccount`, 'POST', JSON.stringify(accountInfos), true);
};

export const deleteAccountById = async accountId => {
  await customFetch(`${getBaseUrl()}/account/delete`, 'POST', JSON.stringify({ accountId }), true);
};

export const getDbAccounts = async () => {
  return await customFetch(`${getBaseUrl()}/account/all`, 'GET', null, true);
};

export const getPasswordLink = async email => {
  return await customFetch(`${getBaseUrl()}/auth/createPasswordLink`, 'POST', JSON.stringify({ email }), false);
};

export const getAccountById = async accountId => {
  return await customFetch(`${getBaseUrl()}/account/getAccountById`, 'POST', JSON.stringify({ accountId }), true);
};

export const getAccountByUserId = async userId => {
  return await customFetch(`${getBaseUrl()}/account/`, 'POST', JSON.stringify({ userId }), true);
};

export const isvalidToken = async (userToken: string) => {
  return await customFetch(`${getBaseUrl()}/auth/isValidToken`, 'POST', JSON.stringify({ userToken }), false);
};

export const login = async (email: string, password: string) => {
  return await customFetch(`${getBaseUrl()}/auth/login`, 'POST', JSON.stringify({ email, password }), true);
};

//***************************************************************************************
//********************************  ACCOUNT    *********************************************
//***************************************************************************************

export const updateDbAccount = async (accountId, data, legalOfficerInitialization?) => {
  const replacer = (key, value) => (value === undefined ? null : value);
  return await customFetch(
    `${getBaseUrl()}/account/updateAccount`,
    'POST',
    JSON.stringify(
      {
        accountId,
        data,
        legalOfficerInitialization,
      },
      replacer
    ),
    true
  );
};

export const updateAccountRights = async (accountId, actionRights) => {
  return await customFetch(
    `${getBaseUrl()}/account/updateRights`,
    'POST',
    JSON.stringify({
      accountId,
      actionRights,
    }),
    true
  );
};

export const updateAccountResponses = async (accountId, entities, users) => {
  return await customFetch(
    `${getBaseUrl()}/account/updateResponses`,
    'POST',
    JSON.stringify({
      accountId,
      entities,
      users,
      date: new Date(),
    }),
    true
  );
};

export const updateAccountEntities = async data => {
  return await customFetch(`${getBaseUrl()}/account/updateAccountEntities`, 'POST', JSON.stringify({ ...data }), true);
};

//*********************************************************************************
//***************************       ENTITY      **********************************
//*********************************************************************************
export const updateDbResponses = async (
  accountId: string,
  userId: string,
  entityId: string,
  versionId: string,
  surveyId: string,
  stepId: string,
  responses: any,
  date: Date
) => {
  return await customFetch(
    `${getBaseUrl()}/entity/updateResponses`,
    'POST',
    JSON.stringify({
      accountId,
      userId,
      entityId,
      versionId,
      surveyId,
      stepId,
      responses,
      date,
    }),
    true
  );
};

export const updateEntityStep = async (adminId, accountId, entityId, stepId, isValidated) => {
  return await customFetch(`${getBaseUrl()}/entity/step`, 'POST', JSON.stringify({ adminId, accountId, entityId, stepId, isValidated }), true);
};

export const duplicateDBEntity = async (accountId: string, entityId: string, entityName: string) => {
  return await customFetch(
    `${getBaseUrl()}/entity/duplicate`,
    'POST',
    JSON.stringify({
      accountId,
      entityId,
      entityName,
    }),
    true
  );
};

export const renameDBEntity = async (accountId: string, entityId: string, entityName: string) => {
  return await customFetch(
    `${getBaseUrl()}/entity/rename`,
    'POST',
    JSON.stringify({
      accountId,
      entityId,
      entityName,
    }),
    true
  );
};

export const deleteDbEntity = async (accountId: string, entityId: string) => {
  return await customFetch(
    `${getBaseUrl()}/entity/delete`,
    'POST',
    JSON.stringify({
      accountId,
      entityId,
    }),
    true
  );
};

export const createEntity = async (accountId, userId, entityName) => {
  return await customFetch(
    `${getBaseUrl()}/entity/create`,
    'POST',
    JSON.stringify({
      accountId,
      userId,
      entityName,
    }),
    true
  );
};

//*********************************************************************************
//*********************     SURVEY      *******************************************
//*********************************************************************************
export const getCompletedSurveyVersionsByAccount = async accountId => {
  return await customFetch(`${getBaseUrl()}/survey/getCompletedSurveyVersions`, 'POST', JSON.stringify({ accountId }), true);
};
//*********************************************************************************
//******************        SENDGRID        ***************************************
//*********************************************************************************
export const getEmailTemplate = async stepName => {
  return await customFetch(`${getBaseUrl()}/sendgrid/getTemplate`, 'POST', JSON.stringify({ stepName }), true);
};

export const getMessagesById = async msgId => {
  return await customFetch(`${getBaseUrl()}/sendgrid/messagesById`, 'POST', JSON.stringify({ msgId }), true);
};

export const getMessagesByEmail = async email => {
  return await customFetch(`${getBaseUrl()}/sendgrid/messagesByEmail`, 'POST', JSON.stringify({ email }), true);
};

export const actionPlanActivation = async data => {
  return await customFetch(`${getBaseUrl()}/account/actionPlanActivation`, 'POST', JSON.stringify({ ...data }), true);
};

export const updateAction = async actionData => {
  return await customFetch(`${getBaseUrl()}/account/updateAction`, 'POST', JSON.stringify(actionData), true);
};

export const addComment = async commentData => {
  return await customFetch(`${getBaseUrl()}/account/addComment`, 'POST', JSON.stringify(commentData), true);
};

export const updateCommentState = async commentData => {
  return await customFetch(`${getBaseUrl()}/account/updateCommentState`, 'POST', JSON.stringify(commentData), true);
};

export const createAction = async actionData => {
  return await customFetch(`${getBaseUrl()}/account/createAction`, 'POST', JSON.stringify({ ...actionData }), true);
};

export const editAction = async actionData => {
  return await customFetch(`${getBaseUrl()}/actionPlan/edit`, 'POST', JSON.stringify({ ...actionData }), true);
};

export const deleteAction = async actionData => {
  return await customFetch(`${getBaseUrl()}/actionPlan/delete`, 'POST', JSON.stringify({ ...actionData }), true);
};

//*********************************************************************************
//*******************       USER        ******************************************
//*********************************************************************************
export const resetDbPassword = async (email: string) => {
  let response = await customFetch(`${getBaseUrl()}/user/resetPassword`, 'POST', JSON.stringify({ email }), false);
  return { error: response.ok, user: response.updatedUser };
};

export const updateUserPassword = async (userId, userToken, password: string) => {
  return await customFetch(
    `${getBaseUrl()}/user/updatePassword`,
    'POST',
    JSON.stringify({
      userId,
      userToken,
      password,
    }),
    false
  );
};

export const createUserPassword = async (userId, userToken, password: string, userName, firstName) => {
  return await customFetch(
    `${getBaseUrl()}/user/createPassword`,
    'POST',
    JSON.stringify({
      userId,
      userToken,
      password,
      userName,
      firstName,
    }),
    false
  );
};

export const shareItemToUser = async data => {
  return await customFetch(
    `${getBaseUrl()}/user/shareItemToUser`,
    'POST',
    JSON.stringify({
      ...data,
    }),
    true
  );
};

export const shareActionPlan = async data => {
  return await customFetch(
    `${getBaseUrl()}/user/shareActionPlan`,
    'POST',
    JSON.stringify({
      ...data,
    }),
    true
  );
};

export const addNotification = async (notificationId, accountId, guestEmail?, sharedItem?, appointementDate?) => {
  const replacer = (key, value) => (value === undefined ? null : value);
  return await customFetch(
    `${getBaseUrl()}/user/addNotification`,
    'POST',
    JSON.stringify(
      {
        notificationId,
        accountId,
        guestEmail,
        sharedItem,
        appointementDate,
      },
      replacer
    ),
    true
  );
};

export const getNotifications = async (accountId, userId) => {
  return await customFetch(
    `${getBaseUrl()}/user/getNotifications`,
    'POST',
    JSON.stringify({
      accountId: accountId,
      userId: userId,
    }),
    true
  );
};
//*********************************************************************************
//******************        AWS S3 - DOCUMENTS      ******************************
//*********************************************************************************
export const getDocumentsListFromS3ByAccountId = async (accountId, entityId?: string) => {
  let keyParam = entityId ? `/${entityId}` : '';

  const response = await customFetch(
    `${getBaseUrl()}/drive/list/`,
    'POST',
    JSON.stringify({
      accountId: accountId,
      entityId: keyParam,
    }),
    true
  );

  return response.map(file => {
    return {
      ...file,
      name: decodeURIComponent(file.Key.split('/').pop()),
      fileType: file.Key.split('.').pop(),
    };
  });
};

export const getDocumentsListFromDirectory = async (accountId, entityId: string, directoryName: string) => {
  const response = await customFetch(
    `${getBaseUrl()}/drive/listFromDirectory`,
    'POST',
    JSON.stringify({
      accountId: accountId,
      entityId: entityId,
      directoryName: directoryName,
    }),
    true
  );

  return response.map(file => {
    return {
      ...file,
      name: decodeURIComponent(file.Key.split('/').pop()),
      fileType: file.Key.split('.').pop(),
    };
  });
};

export const getSignedUrlByKey = async key => {
  const response = await customFetch(`${getBaseUrl()}/drive/signedUrl`, 'POST', JSON.stringify({ Key: key }), true);
  return response;
};

export const getDocumentsFromS3ByKey = async key => {
  const response = await customFetch(`${getBaseUrl()}/drive/getDocument`, 'POST', JSON.stringify({ Key: key }), true);
  return response;
};

export const getDocumentsFromS3ByKeyWithoutError = async key => {
  const response = await customFetch(`${getBaseUrl()}/drive/getDocument`, 'POST', JSON.stringify({ Key: key }), true, true, true);
  return response;
};

export const deleteDocumentFromS3ByKey = async key => {
  const response = await customFetch(`${getBaseUrl()}/drive/deleteDocument`, 'POST', JSON.stringify({ Key: key }), true);
  return response;
};

export const deleteDirectoryFromS3ByKey = async key => {
  const response = await customFetch(`${getBaseUrl()}/drive/deleteDirectory`, 'POST', JSON.stringify({ Key: key }), true);
  return response;
};

export const downloadDirectoryFromS3ByKey = async key => {
  const response = await customFetch(`${getBaseUrl()}/drive/downloadDirectory`, 'POST', JSON.stringify({ Key: key }), true);
  return response;
};

export const uploadDocumentToS3 = async data => {
  return await customFetch(`${getBaseUrl()}/drive/upload`, 'POST', data, true, false);
};

export const removeNotification = async (notificationId, userId) => {
  return await customFetch(
    `${getBaseUrl()}/user/removeNotification`,
    'POST',
    JSON.stringify({
      notificationId,
      userId,
    }),
    true
  );
};

export const updateNotifications = async (userId, updatedNotifications?) => {
  return await customFetch(
    `${getBaseUrl()}/user/updateNotifications`,
    'POST',
    JSON.stringify({ userId, newNotifications: updatedNotifications }),
    true
  );
};

export const updateNotification = async (userId, notification) => {
  return await customFetch(`${getBaseUrl()}/user/updateNotification`, 'POST', JSON.stringify({ userId, notification }), true);
};

export const updateRemaining = async (accountId, action) => {
  let response = await customFetch(`${getBaseUrl()}/user/updateRemaining`, 'POST', JSON.stringify({ accountId, ...action }), true);
  return { error: response.error, account: response.account };
};

export const notifyClientForDocumentsReady = async account => {
  return await customFetch(
    `${getBaseUrl()}/drive/notifyNewDocs`,
    'POST',
    JSON.stringify({
      account,
    }),
    true
  );
};

export const availableCalendarSlots = async data => {
  return await customFetch(`${getBaseUrl()}/calendar/slots`, 'POST', JSON.stringify(data), true);
};

export const bookCalendarSlots = async data => {
  return await customFetch(`${getBaseUrl()}/calendar/create`, 'POST', JSON.stringify(data), true);
};
//*********************************************************************************
//******************         Reminders      **************************************
//*********************************************************************************
export const fireUnchangedActionStatusReminder = () => {
  return customFetch(`${getBaseUrl()}/reminder/unchangedActionStatus`, 'GET', null, true);
};

export const fireActionsDueDateReminder = async () => {
  return customFetch(`${getBaseUrl()}/reminder/actionsDueDate`, 'GET', null, true);
};

export const fireFirstAppointmentReminder = async (accountId?: string) => {
  return customFetch(`${getBaseUrl()}/reminder/firstAppointmentReminder`, 'POST', JSON.stringify({ accountId }), true);
};

export const fireSurveyWithoutCompletionReminder = async (accountId?: string) => {
  return customFetch(`${getBaseUrl()}/reminder/surveyWithoutCompletionReminder`, 'POST', JSON.stringify({ accountId }), true);
};

export const fireSurveyWithPartialCompletionReminder = async (accountId?: string) => {
  return customFetch(`${getBaseUrl()}/reminder/surveyWithPartialCompletionReminder`, 'POST', JSON.stringify({ accountId }), true);
};

export const sendReminderToSelectedAccount = async (templateId: string, wizardRedirection: boolean, accountId: string) => {
  return customFetch(`${getBaseUrl()}/reminder/sendReminderToAccount`, 'POST', JSON.stringify({ templateId, wizardRedirection, accountId }), true);
};

export const getRemindersSummary = async () => {
  return customFetch(`${getBaseUrl()}/reminder/remindersSummary`, 'GET', null, true);
};

export const getRemindersByAccount = async accountId => {
  return customFetch(`${getBaseUrl()}/reminder/remindersByAccount`, 'POST', JSON.stringify({ accountId }), true);
};
//*********************************************************************************
//**************        GENERATION      ******************************************
//*********************************************************************************
export const getDocumentBuffer = async (formData, documentId, entityName, accountId, documentLang) => {
  return await customFetch(
    `${getBaseUrl()}/generate/doc/getDocumentBuffer`,
    'POST',
    JSON.stringify({
      formData,
      documentId,
      entityName,
      accountId,
      documentLang,
    }),
    true
  );
};

export const getRegistryBuffer = async (formData, registryId, entityName, entityId, accountId) => {
  return await customFetch(
    `${getBaseUrl()}/generate/registry/getRegistryBuffer`,
    'POST',
    JSON.stringify({
      formData,
      registryId,
      entityName,
      entityId,
      accountId,
    }),
    true
  );
};
//*********************************************************************************
//***********************       STAT        **************************************
//*********************************************************************************
export const getStats = async () => {
  return await customFetch(`${getBaseUrl()}/account/getStats`, 'GET', null, true);
};

export const getActions = async (accountId, entityId) => {
  return await customFetch(
    `${getBaseUrl()}/actionPlan/all`,
    'POST',
    JSON.stringify({
      accountId,
      entityId,
    }),
    true
  );
};

export const addActionProofChecker = async (accountId, userId, entityId, actionParams) => {
  return await customFetch(
    `${getBaseUrl()}/actionPlan/addProofChecker`,
    'POST',
    JSON.stringify({
      accountId,
      userId,
      entityId,
      actionParams,
    }),
    true
  );
};
//*********************************************************************************
//***************       CONTENTFUL      ******************************************
//*********************************************************************************
interface ContentfulEntry {
  fields: any;
  sys: any;
}

export const fetchContentfulEntryById = async (entryId, locale = 'fr') => {
  try {
    let client = await getClient();
    const survey: ContentfulEntry = await client.getEntry(entryId, { include: 10, locale });
    return {
      id: survey.sys.id,
      name: survey.fields.name,
      index: survey.fields.index,
      conditions: survey.fields.conditions?.conditions,
      versioning: {
        enabled: survey.fields.versioning || false,
        name: survey.fields.versionEntityType || '',
        label: survey.fields.entityTitleLabel,
      },
      steps: parseSteps(survey.fields.steps),
      isDefaultIncluded: survey.fields.entityDefaultIncludedSurvey,
    };
  } catch (error) {
    console.error('Error fetching entry:', error);
  }
};

export const getSurveyById = async surveyId => {
  return await customFetch(`${getBaseUrl()}/contentful/getSurvey`, 'POST', JSON.stringify({ surveyId }), true);
};

export const getSurveyMigrationReport = async formData => {
  return await customFetch(
    `${getBaseUrl()}/survey/getSurveyMigrationReport`,
    'POST',
    JSON.stringify({
      formData,
    }),
    true
  );
};

export const updateSubContractorInfo = async subContractorData => {
  return await customFetch(`${getBaseUrl()}/audit/subContractor/updateInfo`, 'POST', JSON.stringify({ ...subContractorData }), true);
};

export const saveSubContractorResponses = async ({ accountId, responses, subContractorId, isSurveyCompleted }) => {
  return await customFetch(
    `${getBaseUrl()}/audit/subContractor/updateResponses`,
    'POST',
    JSON.stringify({
      accountId,
      responses,
      subContractorId,
      isSurveyCompleted,
    }),
    true
  );
};

export const checkSubContractorSurveyStatus = async subContractorId => {
  return await customFetch(`${getBaseUrl()}/audit/subContractor/checkResponses`, 'POST', JSON.stringify({ subContractorId }), true);
};

export const fetchSubContractorResponses = async subContractorId => {
  return await customFetch(`${getBaseUrl()}/audit/subContractor/fetchResponses`, 'POST', JSON.stringify({ subContractorId }), true);
};

export const validateOrRefuseAudit = async (accountId, entityId, actionId, type, versionProductId, relatedProductId, subContractorId) => {
  return type === 'validate'
    ? await customFetch(
        `${getBaseUrl()}/audit/validate`,
        'POST',
        JSON.stringify({ accountId, entityId, actionId, versionProductId, relatedProductId, subContractorId }),
        true
      )
    : await customFetch(
        `${getBaseUrl()}/audit/refuse`,
        'POST',
        JSON.stringify({ accountId, entityId, actionId, versionProductId, relatedProductId, subContractorId }),
        true
      );
};

export const getInvoices = accountId => {
  return customFetch(`${getBaseUrl()}/invoice/account/${accountId}/all`, 'GET', null, true);
};

export const downloadInvoiceFile = async fileUrl => {
  let customHeaders = new Headers();

  customHeaders.set('Content-Type', 'application/pdf');

  let customOptions: any = {
    method: 'POST',
    headers: customHeaders,
  };

  const tempToken = getSessionValue('tempToken');
  if (tempToken) customHeaders.set('x-temp', tempToken);
  customHeaders.set('x-xsrf-token', window.sessionStorage.getItem('user_token'));
  customOptions = {
    ...customOptions,
    headers: customHeaders,
    mode: 'cors',
    credentials: 'include',
  };

  const customRequest = new Request(`${getBaseUrl()}/invoice/download?fileUrl=${encodeURIComponent(fileUrl)}`, customOptions);

  const response = await fetch(customRequest);
  return response;
};

export const activateBillingSpace = (accountId, userId) => {
  return customFetch(`${getBaseUrl()}/invoice/account/${accountId}/activateBillingSpace`, 'POST', JSON.stringify({ userId }), true);
};
